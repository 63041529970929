<template>
  <v-card class="mb-5">
    <v-skeleton-loader v-if="scanLoading" :loading="true" elevation="2" type="card-heading, image"></v-skeleton-loader>
    <v-toolbar v-if="!scanLoading" :color="color" dark class="mb-5 scan-title-bar" elevation="3">
      <v-toolbar-title class="text-section-title"> Reports</v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <v-row v-for="(pdf, index) in pdfs" :key="index" class="ma-0 pb-3 align-center justify-center cursor-pointer">
      <v-col v-if="selectOpt" class="col-md-1">
        <v-icon size="25" v-if="selectOpt" color="black" @click="fileSelect(pdf)" class="select-img">
          {{ `mdi-${fileSelected.indexOf(pdf.path) !== -1 ? 'check-circle' : 'circle-outline'}` }}
        </v-icon>
      </v-col>
      <v-col @click="openPDF(pdf)" :class="selectOpt ? 'col-md-11' : 'col-md-12'" class="d-flex  align-center">
        <v-icon size="80" color="error darken-2">mdi-file-pdf-box</v-icon>
        <div class="text-h6 font-weight-medium text-break">
          {{ pdf.label ?? 'View PDF' }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['pdfs', 'selectOpt', 'color'],
  emits: ['pdfSelect'],
  data: (vm) => ({
    fileSelected: [],
  }),
  computed: {
    ...mapGetters(['scanLoading']),
  },
  methods: {
    openPDF(pdf) {
      window.open(pdf.url, '_blank');
    },
    basename(path) {
      return path.split('/').reverse()[0];
    },
    fileSelect(pdf) {
      const fileIndex = this.fileSelected.findIndex(path => path === pdf.path);
      if (fileIndex === -1) this.fileSelected.push(pdf.path);
      else this.fileSelected.splice(fileIndex, 1);

      const attachements = this.fileSelected.map((val) => {
        return { "disk": "s3", "path": val, "alias": val.split('/').reverse()[0], "mime": "application\/pdf" }
      });

      this.$emit('pdfSelect', { value: attachements });
    }
  }
}
</script>

<style>

</style>
